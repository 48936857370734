const SVGHero = () => (
  <svg
    width="443"
    height="358"
    viewBox="0 0 443 358"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of two linked circles</title>
    <g clipPath="url(#clip0_2957_26056)">
      <g clipPath="url(#clip1_2957_26056)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.249 279.08C175.622 313.623 260.674 284.576 295.217 214.202C329.759 143.828 300.713 58.7762 230.339 24.2335C159.965 -10.3091 74.9131 18.7378 40.3705 89.1116C5.82778 159.486 34.8747 244.537 105.249 279.08ZM129.962 228.731C172.529 249.624 223.974 232.055 244.867 189.488C265.761 146.922 248.192 95.477 205.625 74.5834C163.058 53.6897 111.614 71.2592 90.7199 113.826C69.8262 156.392 87.3957 207.837 129.962 228.731Z"
          fill="#FC6B1A"
        />
        <mask
          id="mask0_2957_26056"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="-61"
          y="121"
          width="437"
          height="238"
        >
          <path
            d="M341.964 358.361L-60.2872 262.681L-26.6701 121.35L375.581 217.03L341.964 358.361Z"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask0_2957_26056)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.535 13.3567C59.1577 31.0276 11.5671 107.268 29.238 183.645C46.9088 260.022 123.15 307.613 199.526 289.942C275.903 272.271 323.494 196.03 305.823 119.653C288.152 43.2765 211.911 -4.31412 135.535 13.3567ZM148.177 68.0006C101.98 78.6891 73.1938 124.804 83.8823 171.002C94.5708 217.2 140.686 245.985 186.884 235.297C233.081 224.609 261.867 178.493 251.179 132.296C240.49 86.098 194.375 57.3121 148.177 68.0006Z"
            fill="url(#paint0_linear_2957_26056)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M214.58 332.743C284.954 367.286 370.006 338.239 404.549 267.865C439.091 197.491 410.044 112.44 339.67 77.8971C269.297 43.3544 184.245 72.4013 149.702 142.775C115.16 213.149 144.206 298.201 214.58 332.743ZM239.294 282.394C281.861 303.288 333.306 285.718 354.199 243.152C375.093 200.585 357.523 149.14 314.957 128.247C272.39 107.353 220.945 124.923 200.052 167.489C179.158 210.056 196.728 261.501 239.294 282.394Z"
          fill="#FEBE9A"
        />
        <mask
          id="mask1_2957_26056"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="41"
          y="21"
          width="429"
          height="197"
        >
          <path
            d="M41.0127 73.6084L451.154 21.2155L469.562 165.318L59.4209 217.711L41.0127 73.6084Z"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask1_2957_26056)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M355.891 323.595C421.122 280.113 438.753 191.985 395.272 126.754C351.79 61.5232 263.662 43.8918 198.431 87.3731C133.2 130.854 115.569 218.983 159.05 284.214C202.532 349.445 290.66 367.076 355.891 323.595ZM324.782 276.926C364.238 250.626 374.902 197.32 348.602 157.864C322.302 118.408 268.996 107.744 229.54 134.044C190.084 160.344 179.42 213.65 205.72 253.106C232.02 292.562 285.326 303.226 324.782 276.926Z"
            fill="url(#paint1_linear_2957_26056)"
          />
        </g>
        <mask
          id="mask2_2957_26056"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="153"
          y="-6"
          width="293"
          height="221"
        >
          <path
            d="M203.836 -5.79731L445.668 112.905L395.696 214.713L153.864 96.0107L203.836 -5.79731Z"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask2_2957_26056)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105.262 279.053C175.635 313.595 260.687 284.548 295.23 214.174C329.772 143.801 300.726 58.7489 230.352 24.2062C159.978 -10.3365 74.9261 18.7104 40.3834 89.0843C5.84072 159.458 34.8876 244.51 105.262 279.053ZM129.975 228.704C172.542 249.597 223.987 232.028 244.88 189.461C265.774 146.894 248.204 95.4498 205.638 74.5561C163.071 53.6625 111.626 71.2319 90.7328 113.799C69.8391 156.365 87.4086 207.81 129.975 228.704Z"
            fill="#FC6B1A"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2957_26056"
        x1="238.848"
        y1="269.629"
        x2="59.8855"
        y2="241.633"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0143974" stopColor="#FC6B1A" />
        <stop offset="0.333333" stopColor="#B84505" />
        <stop offset="1" stopColor="#FC6B1A" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2957_26056"
        x1="168.805"
        y1="120.252"
        x2="346.136"
        y2="83.3082"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.197917" stopColor="#FDAF84" stopOpacity="0" />
        <stop offset="0.526042" stopColor="#FF6F1F" />
        <stop offset="0.90625" stopColor="#F38F57" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_2957_26056">
        <rect width="443" height="358" fill="white" />
      </clipPath>
      <clipPath id="clip1_2957_26056">
        <rect
          width="478.995"
          height="355"
          fill="white"
          transform="translate(87.5007 -86.1514) rotate(26.1439)"
        />
      </clipPath>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGHero;
